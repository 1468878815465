import React, { useEffect, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { useSearchParams, } from "react-router-dom";
import api from "../api";
import { json } from "./json";
import { MDBContainer, MDBSpinner,  } from "mdb-react-ui-kit";

var myCss = {
  navigation: {
    complete: "sv-btn sv-footer__complete-btn",
  },
};
function Tnps() {
  const [searchParams] = useSearchParams();
  let push_id = searchParams.get("pushid");
  let user_email = searchParams.get("identifier");
  let channel = searchParams.get("channel");
  const [questions, setQuestions] = useState([]);
  const [services, setServices] = useState({ id: "", services: [] });
  const [survey, setSurvey] = useState(new Model(json));
  const [error, setError] = useState(null);
  const [showLanding, setShowLanding] = useState(false);
// const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

// useEffect(() => {
//   const handleResize = () => {
//     setIsMobile(window.innerWidth < 768);
//   };

//   window.addEventListener("resize", handleResize);

//   // Cleanup the event listener on component unmount
//   return () => {
//     window.removeEventListener("resize", handleResize);
//   };
// }, []);

// const paragraphStyle = {
//   width: isMobile ? "auto" : "380px",
//   margin: "0 auto", // Center the paragraph horizontally
// };

// const underlineStyle = {
//   content: '""',
//   display: "block",
//   width: "90%", // Width of the underline
//   height: "3px", // Height of the underline
//   backgroundColor: "#007fbc", // Color of the underline
//   position: "absolute",
//   top: "0",
//   left: "50%",
//   transform: "translateX(-50%)",
// };
  useEffect(() => {
    if (push_id) {
      getPushById(push_id);
    }
  }, [push_id]);

  useEffect(() => {
    if (services.services.length > 0) {
      getQuestions(services.services);
    }
  }, [services.services]);

  useEffect(() => {
    if (channel !== "email") {
      setShowLanding(true);
    }
  }, [channel]);

  useEffect(() => {
    setSurvey(
      new Model(
        JSON.stringify({ elements: questions, showQuestionNumbers: "false" })
      )
    );
  }, [questions]);

  const getQuestions = (services) => {
    api.question
      .getQuestions(services)
      .then((res) => {
        if (res) {
          // res?.data?.data?.questions?.map((q) => {
          //   console.log(q)
          //  const page = survey.addNewPage("All questions")
          //  page.addNewQuestion(q.type, q.id)
          //  page.title =q.text
          // });
          const values = res?.data?.data?.questions?.map((q) => {
            const text = q.text;
            const type = q.type;
            const mod = text[0].toUpperCase() + text.slice(1);
            return type === "rating_scale"
              ? {
                  displayMode: "buttons",
                  type: "rating",
                  description: q.service_name.replaceAll("_", " "),
                  isRequired: true,
                  name: q.id,
                  title: mod,
                  rateMin: 1,
                  rateMax: 10,
                  minRateDescription: {
                    default: "Very unlikely",
                    fr: "Très improbable",
                  },
                  maxRateDescription: {
                    default: "Very likely",
                    fr: "Très probable",
                  },
                  rateDescriptionLocation: "bottom",
                }
              : type === "close_ended" &&
                q.close_ended_metadata.kind === "Checkbox"
              ? {
                  type: "checkbox",

                  name: q.id,
                  title: mod,
                  description: q.service_name.replaceAll("_", " "),
                  choices: q?.close_ended_metadata?.text_options,
                  isRequired: true,
                  colCount: 1,

                  showSelectAllItem: true,
                  separateSpecialChoices: true,
                }
              : type === "close_ended" &&
                q.close_ended_metadata.kind === "Radio"
              ? {
                  type: "radiogroup",
                  name: q.id,
                  title: mod,
                  description: q.service_name.replaceAll("_", " "),
                  isRequired: true,
                  choices: q?.close_ended_metadata?.text_options,
                  colCount: 1,
                }
              : {
                  type: "text",
                  name: q.id,
                  title: mod,
                  placeholder:q?.open_ended_metadata?.placeholder,
                  description: q.service_name.replaceAll("_", " "),
                  isRequired: q?.open_ended_metadata?.min_character_limit===0?false:true,
                };
          });
          setQuestions(values);
        }
      })
      .catch((err) => {});
  };

  survey.css = myCss

  const createResponse = (responses) => {
    api.response
      .createQuestion(responses)
      .then((res) => {
        if (res) {
          //  window.location.replace("https://www.swsc.co.sz/");
        }
      })
      .catch((err) => {});
  };
  console.log(questions);
  const getPushById = (pid) => {
    api.push
      .fetchOnePush(pid)
      .then((res) => {
        if (res) {
          setServices({
            id: res?.data?.data?.id,
            services: res?.data?.data?.service_name,
          });
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      });
  };

  return !showLanding ? (
    <>
      {!error ? (
        <MDBContainer fluid >
          {questions.length > 0 ? (
            <div style={{ maxWidth: "90%", margin: "0 auto" }}>
              <div
                style={{
                  display: "flex",
                  position: "sticky",
                  zIndex: "1000",
                  background: "white",
                  top: "1px",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {" "}
                <img
                  width="100px"
                  alt=""
                  src="https://i.imgur.com/DRb9TIR.png"
                />
                <span
                  className="sd-title  sd-question__title"
                  style={{ margin: "20px", fontWeight: 600 }}
                >
                  <h5>EWSC Experience Survey</h5>
                </span>
              </div>
              <Survey
                onComplete={(sender) => {
                  console.log(sender.data);
                  const responses = [];
                  for (const key in sender.data) {
                    const val = survey.getQuestionByName(key);
                    responses.push({
                      question_id: key,
                      response:
                        typeof val.value == "number"
                          ? `${val.value}`
                          : val.value,
                      answered_by: user_email,
                      pushed_id: push_id,
                    });
                  }

                  createResponse({ responses });
                }}
                model={survey}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <MDBSpinner
                grow
                style={{ width: "3rem", marginTop: "20%", height: "3rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </MDBSpinner>
            </div>
          )}
        </MDBContainer>
      ) : (
        <div
          style={{
            color: "red",
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          An Error Occur: Invalid Push Id
        </div>
      )}
    </>
  ) : (
    <>
      <MDBContainer fluid className="survey-container">
        {/* <div
          style={{
            display: "flex",
            marginTop: "20px",

            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <img
            width="150px"
            style={{ marginTop: "30px" }}
            alt=""
            src="https://i.imgur.com/DRb9TIR.png"
          />
          <h3>EWSC Experience Survey </h3>
          <div style={{ position: "relative", display: "inline-block" }}>
            <div style={underlineStyle}></div>.
            <p
              style={{
                ...paragraphStyle,
                marginBottom: "8px",
                position: "relative",
                paddingTop: "20px",
              }}
            >
              {" "}
              Dear Valued Customer,
            </p>
          </div>
          <p style={paragraphStyle}>
            We Value your feedback and would love to hear from you. Please take
            a brief moment to complete this brief survey to help us improve your
            experience. Your input is greatly appreciated and will help us serve
            your better.
          </p>
          <p style={{ marginTop: "30px" }}>Thank you for your time.</p>
          <MDBBtn
            style={{ background: "#007fbc" }}
            onClick={() => setShowLanding(false)}
          >
            BEGIN
          </MDBBtn>
        </div> */}
        <div className="survey-card-container">
          <div className="survey-card">
            <div className="logo">
              <img
                src="https://i.imgur.com/DRb9TIR.png"
                alt="Eswatini Water Services Corporation Logo"
              />
            </div>
            <h1 className="title">
              <b>EWSC EXPERIENCE SURVEY</b>
            </h1>
            <div className="stars">
              {Array.from({ length: 5 }, (_, index) => (
                <span key={index} className="star">
                  ★
                </span>
              ))}
            </div>
            <p className="message">
              Dear Valued Customer,
              <br />
              We greatly value your feedback and would love to hear your
              thoughts. Please take a moment to complete this brief survey and
              help us enhance your experience. Your input is invaluable to us,
              and we truly appreciate your time and support.
            </p>
            <p className="thank-you">Thank you for your time!</p>
            <button
              onClick={() => setShowLanding(false)}
              className="begin-button"
            >
              <b> BEGIN </b>
            </button>
          </div>
        </div>
      </MDBContainer>
    </>
  );
}

export default React.memo(Tnps);
